import Api from './Api'

export const getBearerToken = (params) => {
  return Api.post('/public/getBearerToken', params)
}

export const getMasterList = () => {
  return Api.get('/dropdown/masterConfigType')
}

export const getProjectListForDropdown = () => {
  return Api.get('/dropdown/getProjectName')
}

// export const getEmployeeNotPMTLAndAdmin = () => {
//   return Api.get('/dropdown/getEmployeeNotPMTLAndAdmin')
// }

export const getEmployeesByTeamId = (teamId) => {
  return Api.get('/dropdown/getEmployeesByTeamId', {
    params: {
      teamId: teamId,
    },
  })
}

export const getReportingMangerByBranchId = (params) => {
  return Api.get('/dropdown/getReportingMangerByBranchId', {
    params: {
      branchId: params,
    },
  })
}

export const getEmployeeByBranchId = (params) => {
  return Api.get('/branch/getEmployeeByBranchId', {
    params,
  })
}

export const getReportingMangerByEmployeeId = (params) => {
  return Api.get('/dropdown/getReportingMangerByEmployeeId', {
    params: {
      employeeId: params,
    },
  })
}

export const getEmployeeNotPMTLAndAdmin = (teamId) => {
  return Api.get('/dropdown/getEmployeeNotPMTLAndAdmin', {
    params: {
      teamId: teamId,
    },
  })
}

export const getEmployeesForTeamEditMasterConfigration = (teamId) => {
  return Api.get('/dropdown/getEmployeesForTeamEditMasterConfigration', {
    params: {
      teamId: teamId,
    },
  })
}

export const getEmployeeByTeamLeadId = (arg) => {
  return Api.get('/dropdown/getEmployeeByTeamLeadId', {
    params: {
      teamLeadId: arg,
    },
  })
}

export const getMasterfieldList = (arg) => {
  return Api.get('/master/config/getFieldsByMasterConfigType', {
    params: {
      type: arg,
    },
  })
}

export const getListbyTablename = (argtabname) => {
  return Api.get('/master/config/findAllDataByTableName', {
    params: argtabname,
  })
}

export const getBranchByCompanyId = (id) => {
  return Api.get('dropdown/getBranchByCompanyId', {
    params: {
      companyId: id,
    },
  })
}

export const saveMasterConfig = (params) => {
  return Api.post('/master/config/save', params)
}

export const saveemployeeTeamMapping = (params) => {
  return Api.post('/employeeTeamMapping/save', params)
}

export const updateMasterConfig = (params) => {
  return Api.post('/master/config/updateDataByTableNameAndId', params)
}

export const editMasterConfig = (params) => {
  return Api.get('/master/config/findDataByIdAndTableName', { params })
}

// Media save
export const mediaUploadSave = (params) => {
  return Api.post('/media/uploadMedia', params)
}
// Delete Media
export const deletMediaById = (params) => {
  return Api.delete('/media/deleteMediaById', {
    params: {
      id: params,
    },
  })
}

export const getBranchfieldList = () => {
  return Api.post('/branch/list')
}

export const getProjectList = (params) => {
  return Api.post('/project/list', params ? params : {})
}

export const getProjectBasedOnUserLevelPMOrBMOrAdmin = () => {
  return Api.get('/dropdown/getProjectBasedOnUserLevelPMOrBMOrAdmin')
}

export const getClientBasedOnUserLevelPMOrBMOrAdmin = () => {
  return Api.get('/dropdown/getClientBasedOnUserLevelPMOrBMOrAdmin')
}

export const getProjectListBasedOnLogin = () => {
  return Api.get('/dropdown/getProjectDropdownBasedOnRole')
}

export const getProjectByBranchId = (params) => {
  return Api.get('/dropdown/getProjectByBranchId', {
    params: {
      branchId: params,
    },
  })
}

export const passwordReset = (params) => {
  return Api.post('/employee/resetPassword', params)
}

export const editProjectList = (editid) => {
  return Api.get('/project/findById', {
    params: {
      id: editid,
    },
  })
}

export const getBudgetVsActualMH = (projectId, wbsId) => {
  return Api.get('/projectResourceAllocation/getBudgetVsActualMH', {
    params: {
      projectId: projectId,
      wbsId: wbsId,
    },
  })
}

export const getTeamTradeRoleProjectMangerByBranchId = (params) => {
  return Api.get('/dropdown/getTeamTradeRoleProjectMangerByBranchId', {
    params: {
      branchId: params,
    },
  })
}

export const getTLByBranchId = (params) => {
  return Api.get('/dropdown/getEmployeeByBranchIdAndTradeRoleDescriptionTL', {
    params: {
      branchId: params,
    },
  })
}

export const getTeamByBranchId = (params) => {
  return Api.get('/dropdown/getTeamByBranchId', {
    params: {
      branchId: params,
    },
  })
}

export const getSubGroupTeams = () => {
  return Api.get('/dropdown/getSubGroupTeams')
}

export const getSubGroupTeamsByBranchId = (params) => {
  return Api.get('/dropdown/getSubGroupTeamsByBranchId', {
    params: {
      branchId: params,
    },
  })
}

export const getTeamEmployeeByTeamProjectMangerId = (params) => {
  return Api.get('/dropdown/getTeamEmployeeByTeamProjectMangerId', {
    params: {
      projectMangerId: params,
    },
  })
}

export const updateCoSubmittedDateByIdAndDate = (params) => {
  return Api.get('/changeOrderRestController/updateCoSubmittedDateByIdAndDate', { params })
}

export const getProjectMTL = () => {
  return Api.get('/dropdown/getProjectManagerAndTL')
}

export const getAllEmployees = () => {
  return Api.get('/dropdown/getAllEmployees')
}

export const getProjectTL = () => {
  return Api.get('/dropdown/getProjectTL')
}

export const getBranch = () => {
  return Api.get('/dropdown/getBranch')
}

export const getEmployeeByTeamLeadIds = (params) => {
  return Api.post('/employeeTeamMapping/getEmployeeByTeamLeadIds', params)
}

export const getTeamsByTeamLeadIds = (params) => {
  return Api.post('/employeeTeamMapping/getTeamsByTeamLeadIds', params)
}

export const getTsApproverByBranchId = (arg) => {
  return Api.get('/dropdown/getTsApproverByBranchId', {
    params: {
      branchId: arg,
    },
  })
}

export const getProjectClientData = () => {
  return Api.get('/project/client')
}

export const getAllWBStList = () => {
  return Api.get('/project/getallwbs')
}

export const saveProject = (params) => {
  return Api.post('/project/save', params)
}

export const getProjectpmtl = () => {
  return Api.get('/project/pmtl')
}

export const getProjectStructureType = () => {
  return Api.get('/project/projectStructureType')
}

export const getCurrencyData = () => {
  return Api.get('/project/currency')
}

export const getProjectStatus = () => {
  return Api.get('/project/getprojectstatus')
}

export const getClientContacts = () => {
  return Api.get('/clientContact/list')
}

export const getClientContactsbyId = (id) => {
  return Api.get('/clientContact/getClientContactByClientId', {
    params: {
      clientId: id,
    },
  })
}

export const getSubGroupData = () => {
  return Api.get('/project/subgroup')
}

export const getSequenceAndLotByProjectAndWbs = (params) => {
  return Api.post('/projectSequence/findSequenceDataByProjectIdAndWBSIds', params)
}

export const getSequenceAndLotByProjectAndWbsForAllocation = (params) => {
  return Api.post('/projectSequence/findSequenceDataByProjectIdAndWBSIdsForAllocation', params)
}

export const getprojectSEQ = () => {
  return Api.get('/projectSequence/projectseq')
}

export const editprojectwbs = (editid) => {
  return Api.get('/projectSequence/findByIdwbs', {
    params: {
      id: editid,
    },
  })
}

export const editProjectseq = (editid) => {
  return Api.get('/projectSequence/findByIdSeq', {
    params: {
      id: editid,
    },
  })
}

export const editProjectlot = (editid) => {
  return Api.get('/projectSequence/findByLot', {
    params: {
      projectSeqLot: editid,
    },
  })
}

export const getlistwbs = (params) => {
  return Api.post('/projectSequence/listwbs', params ? params : {})
}

export const getlistseq = (params) => {
  return Api.post('/projectSequence/listsequence', params ? params : {})
}

export const getlistlot = (params) => {
  return Api.post('/projectSequence/listlot', params ? params : {})
}

export const projectwbssave = (params) => {
  return Api.post('/projectWBS/save', params)
}

export const getprojectWBS = (params) => {
  return Api.post('/projectSequence/save', params)
}

export const getSeqUpload = (params) => {
  return Api.post('/projectSequence/excelUpload', params)
}

export const sequenceDownload = () => {
  return Api.get('/projectSequence/excelDownload')
}

//Percentage upload
export const importMilestonePercentage = (params) => {
  return Api.post('/projectScheduleBaseline/importSchedule', params)
}

export const exportMilestonePercentage = (projectId) => {
  return Api.get('/projectScheduleBaseline/exportSchedule', {
    params: {
      projectId: projectId,
    },
    responseType: 'arraybuffer',
  })
}

export const exportSequence = () => {
  return Api.get('/projectSequence/exportSequence', { responseType: 'arraybuffer' })
}

export const Projectlotsave = (params) => {
  return Api.post('/projectSequence/lotsave', params)
}

export const Projectlotupdate = (params) => {
  return Api.post('/projectSequence/lotupdate', params)
}

export const saveProjectSchedule = (params) => {
  return Api.post('/projectScheduleBaseline/save', params)
}

export const saveUpdateSchedule = (params) => {
  return Api.post('/scheduleRevision/save', params)
}

export const getWbsByProjectId = (wbsid) => {
  return Api.get('/projectWBS/findByProjectId', {
    params: {
      projectId: wbsid,
    },
  })
}

export const getseqprojectId = (wbsid) => {
  return Api.get('/projectSequence/findByProjectId', {
    params: {
      projectId: wbsid,
    },
  })
}

export const findByProjectIdAndWbsId = (params) => {
  return Api.get('/projectSequence/findByProjectIdAndWbsId', { params })
}

// Create CO
export const createCO = (params) => {
  return Api.post('/changeOrderRestController/save', params)
}

export const getProjectSequenceByWBSId = (arg) => {
  return Api.get('/dropdown/getProjectSequenceByWBSId', {
    params: {
      wbsId: arg,
    },
  })
}

export const getprojectSknumberByProId = (wbsid) => {
  return Api.get('/dropdown/getProjectESSKAYNumberByProjectId', {
    params: {
      projectId: wbsid,
    },
  })
}

export const getWBSNumberByProjectId = (arg) => {
  return Api.get('/dropdown/getWBSNumberByProjectId', {
    params: {
      projectId: arg,
    },
  })
}

export const getproClient = () => {
  return Api.get('/dropdown/getClient')
}

export const getClientByProjectId = (params) => {
  return Api.get('/dropdown/getClientByProjectId', {
    params: {
      projectId: params,
    },
  })
}

export const getEmployeeFromSubGroupByProjectId = (params) => {
  return Api.get('/dropdown/getEmployeeFromSubGroupByProjectId', {
    params,
  })
}
// Media upload
export const mediaUpload = (params) => {
  return Api.post('/media/changeOrderMediaUpload', params)
}

// Media download
export const mediaDownload = (params) => {
  return Api.get('/media/findById', {
    params: {
      id: params,
    },
  })
}

//Delete media
export const deleteMediaFile = (params) => {
  return Api.delete(
    '/changeOrderRestController/deleteMediaByChangeOrderIdAndChangeOrderMediaType',
    { params },
  )
}

// Create schedule
export const getScheduleByProjectIdAndWBSIds = (params) => {
  return Api.post('/projectScheduleBaseline/findByProjectIdAndWBSIds', params)
}

export const findByProjectIdWBSIds = (params) => {
  return Api.post('/projectSequence/findByProjectIdAndWBSIds', params)
}

//schedule EDIT
// export const scheduleEdit = (editArg) => {
//   return Api.get('/projectScheduleBaseline/findByProjectIdAndWBSIdAndSeqId', {
//     params: editArg,
//   })
// }

export const scheduleEdit = (argtabname) => {
  return Api.get('/projectScheduleBaseline/findByProjectIdAndWBSIdAndSeqId', {
    params: argtabname,
  })
}

//schedule seq lot list
export const seqIdLot = (params) => {
  return Api.post('/schedule/findseqidgetMilestone', params)
}

//update CO list
export const getUpdateCOList = (params) => {
  return Api.post('/changeOrderRestController/list', params ? params : {})
}

export const getUpdateCOListPMApproved = (params) => {
  return Api.post('/changeOrderRestController/list', params ? params : { isApprovedByPm: 1 })
}

export const updateApprover = (params) => {
  return Api.put('/changeOrderRestController/approvedByPMOrClient', params)
}

export const getClientProByClientId = (id) => {
  return Api.get('/dropdown/getClientJobNumberByClientId', {
    params: {
      clientId: id,
    },
  })
}

export const getEditFinebyId = (editid) => {
  return Api.get('/changeOrderRestController/findById', {
    params: {
      id: editid,
    },
  })
}

//TimeSheet
export const getTSApproverEmployees = () => {
  return Api.get('/dropdown/getTSApproverEmployees')
}

export const getLoginEmployeeTeamIncharges = () => {
  return Api.get('/dropdown/getLoginEmployeeTeamIncharges')
}

export const getTSApproverPMOrTLByProjectId = (params) => {
  return Api.get('/dropdown/getTSApproverPMOrTLByProjectId', {
    params: {
      projectId: params,
    },
  })
}

export const getPMByProjectId = (params) => {
  return Api.get('/dropdown/getPMByProjectId', {
    params: {
      projectId: params,
    },
  })
}

export const getTLByProjectId = (params) => {
  return Api.get('/dropdown/getAllTlByProjectId', {
    params: {
      projectId: params,
    },
  })
}

export const getActivity = (params) => {
  return Api.get('/dropdown/getBillableAndNonBillableActivity', {
    params,
  })
}

export const getTimeSheetActivity = () => {
  return Api.get('/dropdown/getTimeSheetActivity')
}

export const getChangeOrderRefNumber = () => {
  return Api.get('/dropdown/getChangeOrderRefNumber')
}

export const getTimeSheetSubActivityByTimeSheetActivityId = (id) => {
  return Api.get('/dropdown/getTimeSheetSubActivityByTimeSheetActivityId', {
    params: {
      timeSheetActivityId: id,
    },
  })
}

export const getSubActivityByDateAndProjectIdAndWbsIdAndSeqIdAndActivityId = (
  date,
  projectId,
  wbsId,
  seqId,
  activityId,
) => {
  if (isNaN(projectId) || isNaN(wbsId) || isNaN(seqId) || isNaN(activityId)) {
    return []
  } else {
    return Api.get('/dropdown/getSubActivityByDateAndProjectIdAndWbsIdAndSeqIdAndActivityId', {
      params: {
        date: date,
        projectId: projectId,
        wbsId: wbsId,
        seqId: seqId,
        activityId: activityId,
      },
    })
  }
}

export const tsfindById = (id) => {
  return Api.get('timesheet/findById', {
    params: {
      id: id,
    },
  })
}

export const findmilestonetimesheetflag = () => {
  return Api.get('timesheet/findmilestonetimesheetflag')
}

export const timesheetProjectList = (params) => {
  return Api.get('/timesheet/findByProjectId', {
    params,
  })
}

export const timesheetActivityList = () => {
  return Api.get('/dropdown/getTimeSheetActivityForEmployee')
}

export const timesheetActivityListAndDate = (projectId, tsDate, type) => {
  return Api.get('/dropdown/getTimeSheetActivityForEmployeeAndDate', {
    params: {
      projectId: projectId,
      date: tsDate,
      entryType: type,
    },
  })
}

export const timesheetProjectStage = () => {
  return Api.get('/dropdown/getProjectMilestoneByTimeSheetIsTrueForEmployee')
}

export const getTimeSheetSubActivityByActivityIdForEmployee = (id) => {
  return Api.get('/dropdown/getTimeSheetSubActivityByTimeSheetActivityIdForEmployee', {
    params: {
      timeSheetActivityId: id,
    },
  })
}

export const getTimeSheetSubActivityByActivityIdForEmployeeWithDate = (id, tsDate) => {
  return Api.get('/dropdown/getTimeSheetSubActivityByTimeSheetActivityIdForEmployeewithDate', {
    params: {
      timeSheetActivityId: id,
      date: tsDate,
    },
  })
}

export const timesheetWbsByProjectId = (params) => {
  return Api.get('/timesheet/findByProjectIdGetWbs', {
    params: {
      id: params,
    },
  })
}

export const getTimesheetProjectSeq = (params) => {
  return Api.get('/dropdown/getTimesheetProjectSeq', {
    params,
  })
}

export const getTimesheetProjectCor = (params) => {
  return Api.get('/dropdown/getTimesheetProjectCor', {
    params,
  })
}

export const getActivityByDateAndProjectIdAndWbsIdAndSeqId = (params) => {
  return Api.get('/dropdown/getActivityByDateAndProjectIdAndWbsIdAndSeqId', {
    params,
  })
}

export const findByProjectId = (id) => {
  return Api.get('/changeOrderRestController/findByProjectId', {
    params: {
      projectId: id,
    },
  })
}

//Timesheet approval request
export const timeSheetSaveList = (params) => {
  return Api.post('/timesheet/save', params)
}

export const getHolidayAndLeaveList = () => {
  return Api.get('/branchHoliday/getBranchHolidayList')
}

export const timesheetApproveSave = (params) => {
  return Api.post('/timesheet/updatebyid', params)
}

export const timesheetBulkApprove = (params) => {
  return Api.post('/timesheet/statusupdate', params)
}

export const getEmployeeTSApprover = () => {
  return Api.post('/timesheet/statusupdate')
}

export const getRequestedBy = () => {
  return Api.get('/timesheet/getEmpList')
}

export const getTimeSheetCalenderInfoByLoggedInUser = () => {
  return Api.get('/timesheet/getTimeSheetCalenderInfoByLoggedInUser')
}

export const getTimesheetApprovalList = (params) => {
  return Api.post('/timesheet/getTimesheetApprovalList', params ? params : {})
}

export const getDateWiseTimesheetList = (params) => {
  return Api.post('/timesheet/getDateWiseTimesheetList', params)
}

export const findByTimesheetId = (arg) => {
  return Api.get('/timesheet/findById', {
    params: {
      id: arg,
    },
  })
}

//User Management
export const userLevelSaveList = (params) => {
  return Api.post('/menu/saveUserLevelRoleAccess', params)
}
export const employeeLevelSaveList = (params) => {
  return Api.post('/menu/saveEmployeeRoleAccess', params)
}
export const getMenu = (id) => {
  return Api.get('/menu/getMenu', {
    params: {
      menuEnum: id,
    },
  })
}

export const getUserRoleAccessList = (id) => {
  return Api.get('/menu/getUserRoleMenuAccess', {
    params: {
      userRoleId: id,
    },
  })
}

export const getEmployeeAccessList = (id) => {
  return Api.get('/menu/getMenuAccessByEmployeeId', {
    params: {
      employeeId: id,
    },
  })
}

export const getMenuAccessByEmployeeIdAndUserRoleId = (employeeId, userRoleId, parentMenuId) => {
  return Api.get('/menu/getMenuAccessByEmployeeIdAndUserRoleId', {
    params: {
      employeeId: employeeId,
      userRoleId: userRoleId,
      parentMenuId: parentMenuId,
    },
  })
}

export const getMenuAccessByEmployeeIdAndUserRoleIdForRedux = (empId, userRoleId) => {
  return Api.get('/menu/getMenuAccessByEmployeeIdAndUserRoleIdForRedux', {
    params: {
      employeeId: empId,
      userRoleId: userRoleId,
    },
  })
}

export const getMenuForRoleManagement = (id, isEmployeeId) => {
  return Api.get('/menu/getMenuForRoleManagement', {
    params: {
      id: id,
      isEmployeeId: isEmployeeId,
    },
  })
}

//Create Budget
export const projectBudgetSave = (params) => {
  return Api.post('/projectBudget/save', params)
}

export const budgetfindByProjectId = (params) => {
  return Api.post('/projectBudget/findByProjectIdAndWbsId', params)
}

export const getTotalChangeOrderManHoursByProjectIdAndWBSId = (params) => {
  return Api.post('/projectSequence/getTotalChangeOrderManHoursByProjectIdAndWBSId', params)
}

export const getTotalCorMh = (params) => {
  return Api.post('/projectSequence/getTotalCorMhByProjectIdAndWbsIds', params)
}

// Create schedule
export const getScheduleProjectSeq = (params) => {
  return Api.post('/dropdown/getScheduleBaselineSeqNumberByProjectIdAndWbsIds', params)
}
export const getScheduleProjectIdandWbs = (params) => {
  return Api.post('/scheduleRevision/findByProjectIdAndWBSIds', params)
}

export const getScheduleWbsbyProjectId = (id) => {
  return Api.get('/dropdown/getScheduleBaselineWbsNumberByProjectId', {
    params: {
      projectId: id,
    },
  })
}
export const getFindByProjectIdandWbsIdSeq = (argtabname) => {
  return Api.get('/scheduleRevision/findByProjectIdAndWBSIdAndSeqId', {
    params: argtabname,
  })
}

export const getScheduleSeqNum = (params) => {
  return Api.post('/dropdown/getScheduleBaselineSeqNumberByProjectIdAndWbsIds', params)
}

export const getSchedulelist = (params) => {
  return Api.post('/scheduleRevision/list', params)
}

export const updateScheduleSave = (params) => {
  return Api.post('/scheduleRevision/save', params)
}

// Home Page
export const api_currentWeekTargetDate = (startDate, endDate, filter, weeklyFlag) => {
  return Api.post('/scheduleRevision/currentWeekTargetDate', {
    startDate: startDate,
    endDate: endDate,
    filterType: filter,
    weeklyFlag: weeklyFlag,
  })
}

export const api_calenderCoundAndDate = (startDate, endDate, filter) => {
  return Api.post('/scheduleRevision/calenderCoundAndDate', {
    startDate: startDate,
    endDate: endDate,
    filterType: filter,
  })
}

//Generate CO
export const getGenerateCO = (id) => {
  return Api.get('/changeOrderRestController/getGenerateCORequestFormById', {
    params: {
      id: id,
    },
  })
}

export const getInternalSequence = () => {
  return Api.get('/dropdown/getInternalSequence')
}

export const updateLookupWeightedAverage = (params) => {
  return Api.post('/projectSequence/updateLookupWeightedAverage', params)
}

export const getTeamsByProjectId = (id) => {
  return Api.get('dropdown/getTeamsByProjectId', {
    params: {
      projectId: id,
    },
  })
}

export const getMilestoneByProjectIdAndWBSIdAndProjectSequenceId = (params) => {
  return Api.get('/dropdown/getMilestoneByProjectIdAndWBSIdAndProjectSequenceId', { params })
}

export const getBudgetAndEffort = (params) => {
  return Api.get('/projectResourceAllocation/getBudgetAndEffort', { params })
}

export const projectResourceAllocationlist = (params) => {
  return Api.post('/projectResourceAllocation/list', params)
}

export const projectResourceAllocationMyResourceAllocation = (params) => {
  return Api.post('/projectResourceAllocation/myResourceAllocation', params)
}

export const projectResourceAllocationMyResourceAllocationExcel = (reportType, params) => {
  return Api.post('/report/getProjectResourceAllocationReport?type=' + reportType, params, {
    responseType: 'arraybuffer',
  })
}

export const projectResourceAllocationMyResourceAllocationWithoutBranchExcel = (
  reportType,
  params,
) => {
  return Api.post(
    '/report/getProjectResourceAllocationWithoutBranchReport?type=' + reportType,
    params,
    {
      responseType: 'arraybuffer',
    },
  )
}

export const projectResourceAllocationsave = (params) => {
  return Api.post('/projectResourceAllocation/save', params)
}

export const projectResourceAllocationDeallocated = (params) => {
  return Api.post('/projectResourceAllocation/deAllocated', params)
}

export const projectResourceAllocationDeAllocationBySeqIdOrSeqExt = (params) => {
  return Api.post('/projectResourceAllocation/deAllocationBySeqIdOrSeqExt', params)
}

export const getProjectMilestoneByTimeSheetIsTrue = () => {
  return Api.get('/dropdown/getProjectMilestoneByTimeSheetIsTrue')
}

export const getfindProjectDateResponse = (params) => {
  return Api.post('/projectResourceAllocation/findProjectResourceAllocationDateResponse', params)
}

export const getToolTipDataResponse = (params) => {
  return Api.post('/projectResourceAllocation/getToolTipDataResponse', params)
}

export const getPMTLBasedlist = (startDate, endDate, employeeId, projectId, approvalStatus) => {
  return Api.post('/projectResourceAllocation/pmtlBasedlist', {
    approvalStatus: approvalStatus,
    employeeId: employeeId,
    projectId: projectId,
    startDate: startDate,
    endDate: endDate,
  })
}

export const getEmployeeBasedLoginVsTimesheetlist = (startDate, endDate) => {
  return Api.post('/timesheet/employeeBasedLoginVsTimesheetlist', {
    startDate: startDate,
    endDate: endDate,
  })
}

export const getPMTLBasedLoginVsTimesheetlist = (startDate, endDate) => {
  return Api.post('/projectResourceAllocation/pmtlBasedLoginVsTimesheetlist', {
    startDate: startDate,
    endDate: endDate,
  })
}

export const getEmployeeBasedTimesheetCompliance = () => {
  return Api.post('/projectResourceAllocation/employeeBasedTimesheetCompliance', {
    noOfDaysloginVsTimesheet: 1,
    noOfDaysTsEntered: 2,
    noOfDaysApprovalStatus: 2,
  })
}

export const getPMTLBasedTimesheetCompliance = () => {
  return Api.post('/projectResourceAllocation/pmtlBasedTimesheetCompliance', {
    noOfDaysloginVsTimesheet: 1,
    noOfDaysPartialTsEntered: 2,
    noOfDaysPendingApprovalStatus: 5,
  })
}

//reports
export const reportList = (params) => {
  return Api.post('/report/timesheetReport/getEmployeeContributionReport', params)
}

export const reportListexcel = (params) => {
  return Api.post('/report/timesheetReport/getEmployeeContributionReport', params, {
    responseType: 'arraybuffer',
  })
}

export const getMilestonesResourceAllocationAllowed = (id, wbsId, seqId) => {
  return Api.get(
    'dropdown/getMilestoneByProjectIdAndWBSIdAndProjectSequenceIdAndResourceAllocationAllowedTrue',
    {
      params: {
        projectId: id,
        wbsId: wbsId,
        projectSequenceId: seqId,
      },
    },
  )
}

//Reports - PM Report Collection
export const getWeeklyProgressReportRequest = (params) => {
  return Api.post('/report/pmReport/getWeeklyProgressReportRequest', params)
}

export const saveWeeklyReportSummarySupport = (params) => {
  return Api.post('/projectManagementReportRestController/save', params)
}

export const viewWeeklyReportSummarySupport = (params) => {
  return Api.post('/projectManagementReportRestController/list', params)
}

export const getWeeklyProgressReportDownloadRequest = (params) => {
  return Api.post('/report/pmReport/getWeeklyProgressReportRequest', params, {
    responseType: 'arraybuffer',
  })
}

export const projectMonthlyProgressReportView = (params) => {
  return Api.post('/report/pmReport/getMonthlyProgressReportRequest', params)
}

export const projectMonthlyProgressReportDownload = (params) => {
  return Api.post('/report/pmReport/getMonthlyProgressReportRequest', params, {
    responseType: 'arraybuffer',
  })
}

export const projectWeeklyProgressReportView = (params) => {
  return Api.post('/report/pmReport/getProjectWeeklyProgressReportRequest', params)
}

export const projectWeeklyProgressReportDownload = (params) => {
  return Api.post('/report/pmReport/getProjectWeeklyProgressReportRequest', params, {
    responseType: 'arraybuffer',
  })
}

export const ProjectForwardLoadReportView = (params) => {
  return Api.post('/report/pmReport/getProjectForwardLoadReport', params)
}

export const ProjectForwardLoadReportDownload = (params) => {
  return Api.post('/report/pmReport/getProjectForwardLoadReport', params, {
    responseType: 'arraybuffer',
  })
}

export const ResourceAllocationSheetReportView = (params) => {
  return Api.post('/report/pmReport/getResourceAllocationSheetReport', params)
}

export const ResourceAllocationSheetReportDownload = (params) => {
  return Api.post('/report/pmReport/getResourceAllocationSheetReport', params, {
    responseType: 'arraybuffer',
  })
}

export const ResourceCapacityPlanningReportView = (params) => {
  return Api.post('/report/pmReport/getResourceCapacityPlanningReport', params)
}

export const ResourceCapacityPlanningReportDownload = (params) => {
  return Api.post('/report/pmReport/getResourceCapacityPlanningReport', params, {
    responseType: 'arraybuffer',
  })
}

export const getPMYearlyStatusReportTabYear = (params) => {
  return Api.get('/dropdown/getPMYearlyStatusReportTabYear', { params })
}

export const projectStatusReportView = (params) => {
  return Api.post('/report/pmReport/getProjectStatusReport', params)
}

export const projectStatusReportDownload = (params) => {
  return Api.post('/report/pmReport/getProjectStatusReport', params, {
    responseType: 'arraybuffer',
  })
}

export const monthlyReportView = (params) => {
  return Api.post('/report/pmReport/getInternalMRMReport', params)
}

export const monthlyReportDownload = (params) => {
  return Api.post('/report/pmReport/getInternalMRMReport', params, {
    responseType: 'arraybuffer',
  })
}

export const getProjectWhereWbsAllSeqIsIFFComplete = () => {
  return Api.get('/dropdown/getProjectWhereWbsAllSeqIsIFFComplete')
}

export const getEmployeeGroup = () => {
  return Api.get('/dropdown/getEmployeeGroup')
}

export const getWbsAllSeqIsIFFCompleteByProjectId = (id) => {
  return Api.get('/dropdown/getWbsAllSeqIsIFFCompleteByProjectId', {
    params: {
      projectId: id,
    },
  })
}

export const getProjectType = () => {
  return Api.get('/dropdown/projectTypeEnum')
}

export const getParetoAnalysisType = () => {
  return Api.get('/dropdown/paretoAnalysisTypeEnum')
}

export const findProjectStartDateAndEndDateInWeeklyTable = (params) => {
  return Api.get('/projectScheduleBaseline/findProjectStartDateAndEndDateInWeeklyTable', {
    params,
  })
}

export const projectCompletionReportView = (params) => {
  return Api.get('/report/pmReport/getCompletionReport', { params })
}

export const projectCompletionReportDownload = (params) => {
  return Api.get('/report/pmReport/getCompletionReport', { params, responseType: 'arraybuffer' })
}

export const getprojectDropdownForReportFilter = (params) => {
  return Api.get('/dropdown/projectDropdownForReportFilter', {
    params,
  })
}

// Package Submission
export const getprojectsequencenumberbyprojectid = (id) => {
  return Api.get('/dropdown/getProjectSequenceNumberByProjectId', {
    params: {
      projectId: id,
    },
  })
}
//packagelist
export const getEmployeeList = () => {
  return Api.get('/employee/employeelist')
}
export const savePackageList = (params) => {
  return Api.post('/qapackagesubmission/save', params)
}

export const getPackagelist = (params) => {
  return Api.post('qapackagesubmission/list', params)
}
export const getEditPackageId = (editid) => {
  return Api.get('/qapackagesubmission/findByIdPackageSubmission', {
    params: {
      id: editid,
    },
  })
}
export const getfindBfaDateByProjectIdAndWBSIdsSequenceId = (params) => {
  return Api.post('/qapackagesubmission/findBfaDateByProjectIdAndWBSIdsSequenceId', params)
}
//Project Register
export const getProjectRegisterlist = (params) => {
  return Api.post('/qaprojectregister/list', params)
}
export const geteditprojectRegister = (editid) => {
  return Api.get('/qaprojectregister/findByIdqaProjectRegister', {
    params: {
      id: editid,
    },
  })
}
export const saveProjectRegister = (params) => {
  return Api.post('/qaprojectregister/save', params)
}
//lesson learnt
export const getprojectdropdownbylesson = (projectId) => {
  return Api.get('/dropdown/getProjectDropdownBasedOnRoleForLessonsLearnt', {
    params: {
      id: projectId,
    },
  })
}
export const savelessonLearnt = (params) => {
  return Api.post('/lessonsLearnt/save', params)
}
export const getlessonLearntlist = (params) => {
  return Api.post('/lessonsLearnt/filter', params ? params : {})
}
export const getEditLessonId = (editid) => {
  return Api.get('/lessonsLearnt', {
    params: {
      id: editid,
    },
  })
}
//lessonlearntReport
export const lessonreportList = (params) => {
  return Api.post('/report/getLessonsLearntReport', params)
}
export const lessonreportListexcel = (params) => {
  return Api.post('/report/getLessonsLearntReport', params, {
    responseType: 'arraybuffer',
  })
}
//employeecontributionsummaryreports
export const contributionreportList = (params) => {
  return Api.post('/report/timesheetReport/getMonthlyEmployeeContributionSummaryReport', params)
}
export const contributionreportListexcel = (params) => {
  return Api.post('/report/timesheetReport/getMonthlyEmployeeContributionSummaryReport', params, {
    responseType: 'arraybuffer',
  })
}
//budgetreport
export const budgetreworkreportList = (params) => {
  return Api.post('/report/timesheetReport/getWeeklyBudgetVsReworkReport', params)
}
export const budgetreworkreportListexcel = (params) => {
  return Api.post('/report/timesheetReport/getWeeklyBudgetVsReworkReport', params, {
    responseType: 'arraybuffer',
  })
}
//actualconsumptionreport
export const actualconsumptionreportreportList = (params) => {
  return Api.post('/report/timesheetReport/getWeeklyBudgetVsActualConsumptionReport', params)
}
export const actualconsumptionreportreportListexcel = (params) => {
  return Api.post('/report/timesheetReport/getWeeklyBudgetVsActualConsumptionReport', params, {
    responseType: 'arraybuffer',
  })
}
//employeecontributionacrossjobsreport
export const employeecontributionacrossjobsreportList = (params) => {
  return Api.post('/report/timesheetReport/getEmployeeContributionAcrossJobs', params)
}
export const employeecontributionacrossjobsreportListexcel = (params) => {
  return Api.post('/report/timesheetReport/getEmployeeContributionAcrossJobs', params, {
    responseType: 'arraybuffer',
  })
}
//projectawaitingreport
export const getProjectAwaitingReport = (params) => {
  return Api.get('/report/timesheetReport/getProjectsAwaitingBudget', {
    params: {
      branchId: params.branchId,
      reportType: params.reportType,
    },
  })
}

export const getProjectAwaitingReportExport = (params) => {
  return Api.get('/report/timesheetReport/getProjectsAwaitingBudget', {
    params: {
      branchId: params.branchId,
      reportType: params.reportType,
    },
    responseType: 'arraybuffer',
  })
}

//Timesheet Defaulters Report
export const tsDefaultersReportView = (params) => {
  return Api.post('/report/timesheetReport/getTsDefaultersReport', params)
}

export const tsDefaultersReportDownload = (params) => {
  return Api.post('/report/timesheetReport/getTsDefaultersReport', params, {
    responseType: 'arraybuffer',
  })
}

// NCR Internal
export const api_savencr = (params) => {
  return Api.post('/internalNcr/save', params)
}

export const internalUpdateStage = (id, stage) => {
  return Api.get('/internalNcr/updateStage', {
    params: {
      id: id,
      stage: stage,
    },
  })
}

export const api_ncrList = (params) => {
  return Api.post('/internalNcr/list', {
    ncrType: params.ncrType,
    projectNameId: params.projectNameId,
    clientId: params.clientId,
    projectESSKAYNumberId: params.projectESSKAYNumberId,
    clientJobNumber: params.clientJobNumber,
    ncrNumberId: params.ncrNumberId,
    startDate: params.startDate,
    endDate: params.endDate,
  })
}

export const api_listAllNcrNo = (params) => {
  return Api.post('/internalNcr/listAllNcrNo', params)
}

export const ncrInternalReport = (params) => {
  return Api.post('/internalNcr/listV2', {
    clientId: params.clientId,
    projectNameId: params.projectNameId,
    ncrNumberId: params.ncrNumberId,
    ncrType: params.ncrType,
  })
}

export const getEditNcrId = (editid, ncrType) => {
  return Api.get('/internalNcr/findByIdInternalNCR', {
    params: {
      id: editid,
      ncrType: ncrType,
    },
  })
}
// NCR External testttt

export const externalUpdateStage = (id, stage) => {
  return Api.get('/externalNcr/updateExternalStage', {
    params: {
      id: id,
      stage: stage,
    },
  })
}

export const getqamanagername = (id) => {
  return Api.get('/dropdown/getQaManager', {
    params: {
      id: id,
    },
  })
}

export const getQANonConformanceReportPDF = (params) => {
  return Api.get('/report/getQANonConformanceReport', {
    params: {
      qaQcInternalNCRId: params.qaQcInternalNCRId,
      ccExternalNCRId: params.ccExternalNCRId,
      reportType: params.reportType,
    },
    responseType: 'arraybuffer',
  })
}

export const getQANonConformanceReport = (params) => {
  return Api.get('/report/getQANonConformanceReport', {
    params: {
      qaQcInternalNCRId: params.qaQcInternalNCRId,
      ccExternalNCRId: params.ccExternalNCRId,
      reportType: params.reportType,
    },
  })
}
export const getInternalNcrCount = () => {
  return Api.get('/internalNcr/findStatusCount')
}
export const getExternalNcrCount = () => {
  return Api.get('/externalNcr/findExternalStatusCount')
}

export const api_LoginHistorySave = (params) => {
  return Api.post('/loginHistory/save', params)
}

// NCR QMS
export const ncrQmsSave = (params) => {
  return Api.post('/qmsNcr/save', params)
}

export const ncrQmsList = (params) => {
  return Api.post('/qmsNcr/list', params)
}

export const ncrQmsById = (params) => {
  return Api.get('/qmsNcr/findById', {
    params,
  })
}

export const getNCRNo = (params) => {
  return Api.get('/qmsNcr/getNCRNo', {
    params,
  })
}

export const getQmsNcrReportView = (params) => {
  return Api.get('/report/getQmsNcrReport', {
    params,
  })
}

export const getQmsNcrReportDownload = (params) => {
  return Api.get('/report/getQmsNcrReport', {
    params,
    responseType: 'arraybuffer',
  })
}

//Landing page API's
export const employeeBasedLoginVsTimesheetlist = () => {
  return Api.post('/projectResourceAllocation/employeeBasedLoginVsTimesheetlist')
}

export const tsVsloginDifferenceHrs = () => {
  return Api.post('/projectResourceAllocation/tsVsloginDifferenceHrs')
}

export const pmTltsVsloginDifferenceHrs = (startDate, endDate) => {
  return Api.post('/projectResourceAllocation/pmTltsVsloginDifferenceHrs', {
    startDate: startDate,
    endDate: endDate,
  })
}

export const loginVSTSMismatch = (params) => {
  return Api.post('/loginHistory/loginVSTSMismatch', params)
}

export const getTimesheetEntryList = (startDate, endDate, projectId, statusId) => {
  return Api.post('/timesheet/getTimesheetEntryList', {
    projectId: projectId,
    startDate: startDate,
    endDate: endDate,
    statusId: statusId,
  })
}

export const getAllocatedProject = (params) => {
  return Api.post('dropdown/getAllocatedProject', {
    employeeIds: params,
  })
}

export const getPMAndTLTimeSheetEntryList = (params) => {
  return Api.post('timesheet/getPMAndTLTimeSheetEntryList', params)
}

export const getPartialAndNoTimesheetEntries = (params) => {
  return Api.post('/timesheet/getPartialAndNoTimesheetEntries', params)
}

export const getConsumedMHByDate = (params) => {
  return Api.get('/timesheet/getConsumedMHByDate', {
    params,
  })
}

export const getSequenceWithoutTargetDates = () => {
  return Api.get('/projectSequence/getSequencesWithoutTargetDate')
}

//Dropdown client based Project
export const getprojectdropdownbyclient = (clientId) => {
  return Api.get('/dropdown/getProjectByClientId', {
    params: {
      clientId: clientId,
    },
  })
}

//Dropdown branch and client based Project
export const getprojectdropdownbybranchandclient = (branchId, clientId) => {
  return Api.get('/dropdown/getProjectByBranchIdAndClientId', {
    params: {
      branchId: branchId,
      clientId: clientId,
    },
  })
}

export const getClientbyBranchId = (id) => {
  return Api.get('/clientContact/getClientbyBranchId', {
    params: {
      branchId: id,
    },
  })
}

//Leave Entry
export const saveleaveEntry = (params) => {
  return Api.post('/leave/save', params)
}

export const leavelist = (params) => {
  return Api.post('/leave/list', params)
}

export const Traininglist = (params) => {
  return Api.post('/leave/list', params)
}

export const editLeaveandTraining = (id, type) => {
  return Api.get('/leave/findLeaveDetailbyId', {
    params: {
      id: id,
      type: type,
    },
  })
}

export const DeleteList = (id, type) => {
  return Api.delete('/leave/deletebyIdLeaveOrTraining', {
    params: {
      id: id,
      type: type,
    },
  })
}

export const getMilestoneTimesheetByMilestoneId = (id) => {
  return Api.get('/dropdown/getMilestoneTimesheetByMilestoneId', {
    params: {
      milestoneId: id,
    },
  })
}

export const saveMilestoneTimesheet = (params) => {
  return Api.post('/projectMilestone/saveMilestoneTimesheet', params)
}

// TODO:
export const saveToDoList = (params) => {
  return Api.post('/todolist/save', params)
}

export const deleteToDoList = (params) => {
  return Api.delete('/todolist/delete', { params })
}

export const viewToDoList = () => {
  return Api.get('/todolist/list')
}

//QM REPORTS:
export const projectRegisterReportView = (params) => {
  return Api.post('/report/getProjectRegisterReport', params)
}

export const projectRegisterReportDownload = (params) => {
  return Api.post('/report/getProjectRegisterReport', params, {
    responseType: 'arraybuffer',
  })
}

//Notifications
export const viewNotificationList = () => {
  return Api.get('/notification/list')
}

export const getProjectProgressMonitoringList = () => {
  return Api.get('/notification/projectProgressMonitoringList')
}

export const getQcQaNotificationList = () => {
  return Api.get('/notification/qcqaNotificationList')
}

export const updateNotificationList = (args) => {
  return Api.get('/notification/updateActionflag', {
    params: {
      id: args.id,
    },
  })
}

export const getQmNotificationList = () => {
  return Api.get('/notification/qmNotificationlist')
}

export const getQaQcNotificationDetails = (params) => {
  return Api.get('/notification/qmNotificationDetaillist', {
    params,
  })
}
//package Dropdown
export const packageDropdownlist = () => {
  return Api.get('/dropdown/getPackageNo')
}

export const getPackageSubmissionNumber = (args) => {
  return Api.get('/dropdown/getPackageSubmissionNumber', {
    params: {
      clientId: args.id,
    },
  })
}

export const getPackageSubmissionNumberByClientIdOrProjectId = (args) => {
  return Api.get('/dropdown/getPackageSubmissionNumberByClientIdOrProjectId', {
    params: {
      clientId: args.clientId,
      projectId: args.projectId,
    },
  })
}

//Scheduler triggers
export const addInWeeklyProgressPercentageMapping = (params) => {
  return Api.get('/projectScheduleBaseline/addInWeeklyProgressPercentageMapping', {
    params,
  })
}

//Drawing Check
export const drawingCheckHeader = (params) => {
  return Api.post('/drawingCheckHeader/save', params)
}

export const drawingCheckHeaderList = (params) => {
  return Api.post('/drawingCheckHeader/list', params)
}

export const drawingCheckHeaderDelete = (params) => {
  const queryString = new URLSearchParams(params).toString()
  return Api.post(`/drawingCheckHeader/delete?${queryString}`)
}

// export const readDrawingFiles = (editid) => {
//   return Api.get('/drawingCheckHeader/fetchDrawingNumbersFromFTP?id=' + editid)
// }

export const readDrawingFiles = (params) => {
  return Api.get('/drawingCheckHeader/fetchDrawingNumbersFromFTP', {
    params,
  })
}

export const allocateDrawingCheck = (params) => {
  return Api.post('/drawingCheckHeader/updateEmployeeIdDrawingCheckMapping', params)
}

export const allocateEmployeeForReport = (params) => {
  return Api.post('/drawingCheckHeader/updateEmployeeIds', params)
}

export const getAssignedEmployeeIdsByHeaderId = (params) => {
  return Api.get('/drawingCheckHeader/getAssignedEmployeeIds', {
    params,
  })
}

export const readCalloutText = (editid) => {
  return Api.post('/drawingCheckHeader/executeDrawingCheck?id=' + editid)
}

export const drawingCheckDetailsList = (params) => {
  return Api.post('/drawingCheckDetails/list', params)
}

export const editInsertedFilesList = (params) => {
  return Api.post('/drawingCheckDetails/editFileNumbers', params)
}

export const moveToNextProcess = (params) => {
  const queryParams = new URLSearchParams(params).toString()
  return Api.post(`/drawingCheckDetails/moveToNextProcess?${queryParams}`)
}

export const findDrawingCheckDetails = (params) => {
  return Api.get('/drawingCheckDetails/findByDrawingCheckHeaderId', {
    params,
  })
}

export const getDrawingCheckerNameBasedOnProject = (params) => {
  return Api.get('/drawingCheckHeader/getDrawingCheckerNameBasedOnProject', {
    params,
  })
}

export const getAssignedDrawingNumbersByHeaderId = (params) => {
  return Api.get('/drawingCheckHeader/getAssignedDrawingNumbersByHeaderId', {
    params,
  })
}

export const getAssignedDrawingNumbersByHeaderIdForDetailer = (params) => {
  return Api.get('/drawingCheckHeader/getAssignedDrawingNumbersByHeaderIdForDetailer', {
    params,
  })
}

export const getAssignedDrawingNumbersByHeaderIdForModeller = (params) => {
  return Api.get('/drawingCheckHeader/getAssignedDrawingNumbersByHeaderIdForModeller', {
    params,
  })
}

export const getDrawingNumbersByHeaderId = (params) => {
  return Api.get('/drawingCheckHeader/getDrawingNumbersByHeaderId', {
    params,
  })
}
export const getDrawingNumbersByHeaderIdForDetailer = (params) => {
  return Api.get('/drawingCheckHeader/getDrawingNumbersByHeaderIdForDetailer', {
    params,
  })
}

export const findDrawingCheckHeader = (params) => {
  return Api.get('/drawingCheckHeader/findById', {
    params,
  })
}

export const uploadDrawingList = (params) => {
  return Api.post('/drawingCheckDetails/excelUpload', params)
}

export const uploadFilesToFtp = (params) => {
  return Api.post('/drawingCheckDetails/uploadFilesToFtp', params)
}

export const addDrawingList = (params) => {
  return Api.post('/drawingCheckDetails/insertFileNumbers', params)
}

export const getModifyDrawingList = (params) => {
  return Api.get('/drawingCheckDetails/getDrawingFileNames', {
    params,
  })
}

export const updateModifyDrawingList = (params) => {
  return Api.delete('/drawingCheckDetails/deleteByDrawingCheckHeaderIdAndDrawingNumbers', {
    data: params,
  })
}

export const getDrawingCheckReportNumbers = (params) => {
  return Api.post('/dropdown/getDrawingCheckReportNumbers', params)
}

export const drawingCheckDetailsSave = (params) => {
  return Api.post('/drawingCheckDetails/save', params)
}

export const optimizeReourceAllocation = (params) => {
  return Api.get('/projectResourceAllocation/optimizeReourceAllocation', {
    params,
  })
}

export const deactivateDrawingCheckDetails = (params) => {
  return Api.delete('/drawingCheckDetails/deactivateDrawingCheckDetails', {
    data: params,
  })
}

export const getDCReportView = (params) => {
  return Api.post('/report/qmReport/getDCReport', params)
}

export const getDCReport = (params) => {
  return Api.post('/report/qmReport/getDCReport', params, {
    responseType: 'arraybuffer',
  })
}

export const deleteDrawingCheckRemark = (params) => {
  return Api.delete('/drawingCheckDetails/deleteRemarks', {
    data: params,
  })
}

export const sequenceWiseMhReportView = (params) => {
  return Api.post('/report/timesheetReport/getSequenceWiseMhReportRequest', params)
}

export const sequenceWiseMhReportDownload = (params) => {
  return Api.post('/report/timesheetReport/getSequenceWiseMhReportRequest', params, {
    responseType: 'arraybuffer',
  })
}

export const drawingCheckReportLogView = (params) => {
  return Api.post('/report/qmReport/getDCLogReport', params)
}

export const drawingCheckReportLogDownload = (params) => {
  return Api.post('/report/qmReport/getDCLogReport', params, {
    responseType: 'arraybuffer',
  })
}

export const getQAReportView = (params) => {
  return Api.post('/report/qmReport/getQADCReport', params)
}

export const getQAReport = (params) => {
  return Api.post('/report/qmReport/getQADCReport', params, {
    responseType: 'arraybuffer',
  })
}

export const QACheckReportLogView = (params) => {
  return Api.post('/report/qmReport/getQADCReportLog', params)
}

export const QACheckReportLogDownload = (params) => {
  return Api.post('/report/qmReport/getQADCReportLog', params, {
    responseType: 'arraybuffer',
  })
}

export const QACheckRegisterReportView = (params) => {
  return Api.post('/report/qmReport/getQADCRegisterReport', params)
}

export const QACheckRegisterReportDownload = (params) => {
  return Api.post('/report/qmReport/getQADCRegisterReport', params, {
    responseType: 'arraybuffer',
  })
}

export const drawingCheckRegisterReportView = (params) => {
  return Api.post('/report/qmReport/getDCRegisterReport', params)
}

export const drawingCheckRegisterReportDownload = (params) => {
  return Api.post('/report/qmReport/getDCRegisterReport', params, {
    responseType: 'arraybuffer',
  })
}

export const CustomerComplaintRegisterReportView = (params) => {
  return Api.post('/report/qmReport/getCCRegister', params)
}

export const CustomerComplaintRegisterReportDownload = (params) => {
  return Api.post('/report/qmReport/getCCRegister', params, {
    responseType: 'arraybuffer',
  })
}

//Model Check
export const modelCheckHeaderList = (params) => {
  return Api.post('/modelCheckHeader/list', params)
}

export const getModelCheckHeaderListById = (params) => {
  return Api.get('/modelCheckHeader/findById', {
    params,
  })
}

export const modelCheckHeaderSave = (params) => {
  return Api.post('/modelCheckHeader/save', params)
}

export const getModelCheckReportNumbers = (params) => {
  return Api.post('/dropdown/getModelCheckReportNumbers', params)
}

export const getMCReportView = (params) => {
  return Api.post('/report/qmReport/getDCModelReport', params)
}

export const getMCReport = (params) => {
  return Api.post('/report/qmReport/getDCModelReport', params, {
    responseType: 'arraybuffer',
  })
}

export const ModelCheckReportLogView = (params) => {
  return Api.post('/report/qmReport/getDCModelLogReport', params)
}

export const ModelCheckReportLogDownload = (params) => {
  return Api.post('/report/qmReport/getDCModelLogReport', params, {
    responseType: 'arraybuffer',
  })
}

export const paretoChartModelView = (params) => {
  return Api.post('/report/qmReport/getModelParetoChart', params)
}

export const paretoChartModelDownload = (params) => {
  return Api.post('/report/qmReport/getModelParetoChart', params, {
    responseType: 'arraybuffer',
  })
}

export const paretoChartQaView = (params) => {
  return Api.post('/report/qmReport/getQaParetoChart', params)
}

export const paretoChartQaDownload = (params) => {
  return Api.post('/report/qmReport/getQaParetoChart', params, {
    responseType: 'arraybuffer',
  })
}

export const paretoChartDrawingView = (params) => {
  return Api.post('/report/qmReport/getDrawingParetoChart', params)
}

export const paretoChartDrawingDownload = (params) => {
  return Api.post('/report/qmReport/getDrawingParetoChart', params, {
    responseType: 'arraybuffer',
  })
}

export const ModelCheckRegisterReportView = (params) => {
  return Api.post('/report/qmReport/getDCModelRegisterReport', params)
}

export const ModelCheckRegisterReportDownload = (params) => {
  return Api.post('/report/qmReport/getDCModelRegisterReport', params, {
    responseType: 'arraybuffer',
  })
}

// Quality Check
export const qaCheckHeader = (params) => {
  return Api.post('/drawingCheckHeaderQA/save', params)
}

export const drawingCheckHeaderListQA = (params) => {
  return Api.post('/drawingCheckHeaderQA/list', params)
}

export const getDCReportQAView = (params) => {
  return Api.post('/report/qmReport/getDCReportQA', params)
}

export const getDCReportQA = (params) => {
  return Api.post('/report/qmReport/getDCReportQA', params, {
    responseType: 'arraybuffer',
  })
}

export const getDrawingNumbersByHeaderIdQA = (params) => {
  return Api.get('/drawingCheckHeaderQA/getDrawingNumbersByHeaderId', {
    params,
  })
}

export const getDrawingNumbersForDetailerByHeaderId = (params) => {
  return Api.get('/drawingCheckHeaderQA/getDrawingNumbersForDetailerByHeaderId', {
    params,
  })
}

export const findDrawingCheckDetailsQA = (params) => {
  return Api.get('/drawingCheckDetailsQA/findByDrawingCheckHeaderId', {
    params,
  })
}

export const findDrawingCheckHeaderQA = (params) => {
  return Api.get('/drawingCheckHeaderQA/findById', {
    params,
  })
}

export const getAssignedDrawingNumbersByHeaderIdQA = (params) => {
  return Api.get('/drawingCheckHeaderQA/getAssignedDrawingNumbersByHeaderId', {
    params,
  })
}

export const getAssignedDrawingNumbersToDetailerByHeaderId = (params) => {
  return Api.get('/drawingCheckHeaderQA/getAssignedDrawingNumbersToDetailerByHeaderId', {
    params,
  })
}

export const deleteDrawingCheckRemarkQA = (params) => {
  return Api.delete('/drawingCheckDetailsQA/deleteRemarks', {
    data: params,
  })
}

export const drawingCheckDetailsSaveQA = (params) => {
  return Api.post('/drawingCheckDetailsQA/save', params)
}

// export const readDrawingFilesQA = (editid) => {
//   return Api.get('/drawingCheckHeaderQA/fetchDrawingNumbersFromFTP?id=' + editid)
// }

export const readDrawingFilesQA = (params) => {
  return Api.get('/drawingCheckHeaderQA/fetchDrawingNumbersFromFTP', {
    params,
  })
}

export const readCalloutTextQA = (editid) => {
  return Api.post('/drawingCheckHeaderQA/executeDrawingCheck?id=' + editid)
}

export const allocateDrawingCheckQA = (params) => {
  return Api.post('/drawingCheckHeaderQA/updateEmployeeIdDrawingCheckMapping', params)
}

export const updateDetailerEmployeeIdDrawingCheckMapping = (params) => {
  return Api.post('/drawingCheckHeaderQA/updateDetailerEmployeeIdDrawingCheckMapping', params)
}

export const deactivateDrawingCheckDetailsQA = (params) => {
  return Api.delete('/drawingCheckDetailsQA/deactivateDrawingCheckDetails', {
    data: params,
  })
}

export const getDrawingCheckerNameBasedOnProjectQA = (params) => {
  return Api.get('/drawingCheckHeaderQA/getDrawingCheckerNameBasedOnProject', {
    params,
  })
}

export const getDrawingCheckQAReportNumbers = (params) => {
  return Api.post('/dropdown/getDrawingCheckQAReportNumbers', params)
}

export const getEmployeeByrole = (params) => {
  return Api.post('/employee/findByrole', params)
}

export const getEmployeeBasedOnProjectAndTradeRole = (params) => {
  return Api.post('/drawingCheckHeaderQA/getEmployeeBasedOnProjectAndTradeRole', params)
}

// Dashboard
export const getPmDashboardDetails = (params) => {
  return Api.post('/dashboard/pmdashboard', params)
}

export const getBmDashboardDetails = (params) => {
  return Api.post('/dashboard/bmdashboard', params)
}
